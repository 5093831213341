import React from 'react';
import './App.css';  
import DialPad from './components/dialpad/DialPad';
import Directory from './components/directory/Directory';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Status from './components/status/Status';
import Notification from './components/Notification';
import Session from './components/Session';
import Profile from './components/profile/Profile';
import ring from './incoming.mp3';
import MessagingApp from './components/msg/MessagingApp';
import VoicemailApp from './components/voicemail/VoicemailApp';
import Login from './components/Login/Login';
import * as requests from './requests';
import CallLogs from './components/call_logs/CallLogs';
import notificationImg from './images/notification.png'
import homeImg from './images/home.png'
import offline_img from './images/offline.png'
import redDotImg from './images/red_dot.png'
import { MainContext } from './contexts/MainContext';
import CallOperator from './components/call_operator/CallOperator';
import { NewMessageIcon } from './components/msg/NewMessageIcon';
const axios = require('axios');

const _ = require('lodash');


const JsSIP = require('jssip')

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      CallLogs:false,
      calls:[],
      call_options : {
        mediaConstraints: {   
            audio: true,
            video: false
        },
        pcConfig:
        {
            rtcpMuxPolicy : 'negotiate',
            iceServers    :
            [
                { urls : [ 'stun:stun.tonet.io:3478' ] }
            ]
        }
      },
      conversations:[],
      conversation:{
        carrier:false,
        messages:[],
        endpoints:[]
      },
      dialPad: false,
      directory:false,
      dnd:false,
      home:true,
      loading:true,
      message_notifications:[],
      messaging_app:false,
      missedCalls:[],
      mix_calls_modal:false,
      new_messages:[],
      sessions:[],
      selected_call:null,
      team_or_contacts:true ,  //True shows Team, False shows Contacts
      group_or_myself:true ,  //True shows Myself, False shows Group
      text_targets:[],
      token:"",
      outbound_text:"",
      outbound_call:"",
      transfer_call_modal:false,
      voicemails:[],
      sent_message_timestamp:Date.now(),
      message_checker_timestamp:Date.now()+5,
      registered:true,
      update_global:this.updateGlobalState

    
    };
    this.showHideComponent = this.showHideComponent.bind(this);
    }
  
  // registerServiceWorker = async () => {
  //   const swRegistration = await navigator.serviceWorker.register('service-worker.js'); //notice the file name
  //   return swRegistration;
  // }
  
  incoming_notification_options =(type,tag,src,vibrate,kwarg={})=> {
    return  {
    icon: notificationImg,
    vibrate: vibrate,
    tag:tag,
    data: {...kwarg,
      type:type,
      src:src
    },
    requireInteraction:true,
    actions: [
      {action: 'explore', title: 'Check',
        icon: homeImg},
      {action: 'close', title: 'Close',
        icon: redDotImg},
    ]
}
  }
  windowNotification = ()=>{
    if (!this.windowInterval)
    {
    this.windowInterval = setInterval(() => {
      if (this.state.documentTitle === "Tonet App")
      {document.title="New Message"}
    
    else {
      document.title="Tonet App"
    }
  }
    , 2000);
  }
}

  setToken = (token) =>{
    this.setState({token:token})
  }
  checkToken = () => {
    let token, _id;
    
    // Check if token exists in URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    
    // If we found a URL token, store it and set header
    if (urlToken) {
        localStorage.setItem('token', urlToken);
        axios.defaults.headers.common['x-access-token'] = urlToken;
        // Optionally remove token from URL to clean it up
        window.history.replaceState({}, document.title, window.location.pathname);
    }
    
    // On every check, ensure header is set from localStorage if it exists
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
        axios.defaults.headers.common['x-access-token'] = storedToken;
    }
    
    // Now proceed with normal token check
    let user = requests.checkToken().then(
        (data) => { 
            if (data["token"] === "none" || data["token"] === "expired") {
                token = ""
                _id = ""
                localStorage.removeItem('token'); // Clear invalid token
            }
            else {
                token = data["token"]
                _id = data["_id"]
            }
            this.setState({
                "token": token,
                "_id": _id,
                user: data['user']
            })
            return data['user']
        }
    )
    return user
}
  //  notifyMe = () => {
  //   // Let's check if the browser supports notifications
  //   if (!("Notification" in window)) {
  //     alert("This browser does not support desktop notification");
  //   }
    
  //   // Let's check whether notification permissions have already been granted
  //   else if (window.Notification.permission === "granted") {
  //     // If it's okay let's create a notification
      
  //     navigator.serviceWorker.getRegistration().then(reg => {
  //       this.setState({sw:reg})
  //     }).catch((e) =>{
  //       console.log(e)
  //     })
  //   }
  
  //   // Otherwise, we need to ask the user for permission
  //   else if (window.Notification.permission !== "denied") {
  //     window.Notification.requestPermission();
  //   }
   
  //   // At last, if the user has denied notifications, and you
  //   // want to be respectful there is no need to bother them any more.
  // }
  // clearNotification = () => {
  //   window.focus();
  //   if(this.windowInterval)
  //   {
  //   clearInterval(this.windowInterval)
  //   delete this.windowInterval
  //   document.title="tonet app"
  //   }
  // }
  
  missed_call_checker = async (calls) =>{ 

        let missedCalls = []
        for (const call of calls)
        {
          if ((new Date(this.state.user.check_in.call_logs)>new Date(call.end)) )
          {break}
          else
          if ( (call.direction ==="INCOMING") && (call.disposition !=="ANSWER"))
          { 
            missedCalls.push(call)
          }
          else if( (call.direction ==="OUTGOING") && (call.disposition !=="ANSWER") &&  (call.dstdid ===this.state.user.extension))
          {
            missedCalls.push(call)
          }
        }
        this.setState({missedCalls:missedCalls})
  }
  
  new_messages_checker = async () => 
  {
        if(this.state.token.length>0) //check connected
        {  
          this.setState({message_checker_timestamp:Date.now()})
          await requests.get_conversations().then
          (response => 
          {
            if (this.state.message_checker_timestamp<this.state.sent_message_timestamp)
            {
              return
            }
            const conversations = response.data.sort((a, b) => (Date.parse(a.last_updated) > Date.parse(b.last_updated)) ? -1 : 1)
            this.setState({conversations:conversations})
            let conversation = conversations.find(o=> o._id === this.state.conversation._id)
            if (conversation) 
            {
              this.setState({conversation:conversation})
            }
          }
          ).then
          (()=>
          {
            let new_messages = []
            for(let i = 0; i<this.state.conversations.length; i++)
            {
              let conversation = this.state.conversations[i]
              let user_last_read = ""
              if (conversation['checked_in'])
                {user_last_read = Date.parse(conversation['checked_in'])|| ""}
              for(let k =  conversation['messages'].length-1; k>=0; k--)
              { 
                let message_timestamp = conversation['messages'][k]['timestamp_created']
                if( Date.parse(message_timestamp) > user_last_read  && conversation['messages'][k].author !== this.state._id)
                { 
                  let new_message = conversation['messages'][k]
                  new_message['_id']= conversation['_id']
                  new_messages.push(conversation['messages'][k])
                  let already_notified = false;
                  //for (let n=0 ; n< this.state.message_notifications.length; n++)
                  for (const message_notification of this.state.message_notifications )
                   {
                     if (_.isEqual(message_notification, conversation['messages'][k]))
                     { already_notified = true
                       break
                     }
                   }
                   if (!already_notified)
                   {
                    //  if (document.hidden) 
                    //  {
                    //    let author_id = conversation['messages'][k]['author']
                    //    let author_name
                    //    let message
                    //        if (conversation['carrier']){
                    //          author_name = author_id
                    //          message = `${author_name}:${conversation['messages'][k]['body']}`
                    //        }
                    //        else{
                    //          let author
                    //          author = this.state.team.find((o)=> o._id === author_id)
                    //          if (author)
                    //          {
                    //            author_name = author['display_name']
                    //            message = `${author_name}:${conversation['messages'][k]['body']}`
                    //           }
                    //          else{
                    //            author_name=""
                    //            message = `${conversation['messages'][k]['body']}`
                    //          }
                    //        }
                    //    navigator.serviceWorker.getRegistration().then
                    //    (reg => 
                    //      {
                    //        // TODO 2.4 - Add 'options' object to configure the notification
                           
                    //        let notification = reg.showNotification(message,this.incoming_notification_options(
                    //          "message",
                    //          conversation['messages'][k]['_id'],
                    //          conversation['messages'][k]['author'],
                    //          [2000, 10000, 2000],
                    //          {conversation_id:conversation._id}
                    //          ));
                    //        this.windowNotification()
                    //      }
                    //    )
                    //    //new window.Notification(`${author_name}:${conversation['messages'][k]['body']}`)  
                    //  }
                     let notifications = [...this.state.message_notifications]
                     notifications.push(conversation['messages'][k])
                     this.setState({message_notifications: notifications})
                   }
                }
              }
            }
            this.setState({new_messages:new_messages})
            return 'success'
          }).catch((err)=>
          {
            console.log(err)

          }
          )
        }
  }
  
  get_permissions= async ()=>{
    await requests.getPermissions().then(
      (result) => { 
        this.setState({permissions: result})
        if(result.outbound_call.length ===1){
          requests.update_outbound_number(result.outbound_call[0].number).then(res=>this.setState({'user':res.user}))
        }
      }
    )
  }
  
  update_team = async (team) => {
    let team_copy = [...team] //this is to change an online user to offline if too long afk
    for (const user of team_copy)
    {
    if ( user.check_in.app)  {
      if (Date.now()>(180000+Date.parse(user.check_in.app)))
      {
        user['status']=0
      }
    }
    else{
      user['status']=0
    }
    if (user['display_name'])
    {
      user['display_name']=user['display_name'].toLowerCase()
    }
    }
    const orderedList = _.orderBy(team_copy, ['status', 'display_name'], ['desc', 'asc']);
      this.setState({team:orderedList}) 
    }

  
  update_app = async ()=>{
    if (this.state.token)
    {
      await requests.update_app().then(response => {
        this.setState({user:response.user,voicemails:response.voicemails})
        if (response.calls){
          this.setState({calls:response.calls})
          this.missed_call_checker(response.calls)
        }
        if (response.team){
          this.update_team(response.team)
        }
        if (response.archived_team){
          this.setState({"archived_team":response.archived_team})
        }
        
      })
    }
  }
  get_phones_groups = async () =>{
    await requests.get_phones_groups().then(res=>{
      if (res.success){
        this.setState({
          groups:res.groups,
          phones:res.phones
        })
      }
      else{
        setTimeout(this.get_phones_groups,10000)
      }
    })
  }
  check_registration = ()=>{
    try{
      if (!window.phone?.isRegistered())
      {
        window.phone.stop()
        window.phone.start()
        window.phone.register()
      }
    }
    catch (e){
      console.log(e)
    }
  }
  refresh_sip= ()=>{
    if (this.state.sessions.length ===0){
      window.phone.stop()
      window.phone.start()
      console.log('refreshed')
    }
  }
  startApp = async (user)=>{
  await Promise.all([
      this.registerSip(user)],
      this.update_app(),
      this.get_permissions(),
      this.get_phones_groups(),
      this.new_messages_checker()
      )
      //this.notifyMe()
      // this.registerServiceWorker()
      setInterval(this.check_registration,10000)
      setInterval(this.update_app,20000)
      setInterval(this.new_messages_checker,10000)
      setInterval(this.refresh_sip,100000)
  }
  run_registered_listeners = () =>{
    const phone = window.phone
    phone.on('disconnected' , ()=>{
      console.log('disconnected')
      this.setState({registered:false})
    })
    phone.on('unregistered' , ()=>{
      console.log('unregistered')
      this.setState({registered:false})
    })
    phone.on('registered' , ()=>{
      console.log('registered')
      this.setState({registered:true})
    })
  }
  componentDidMount(){
    document.addEventListener('click',(e)=>{
      try{
        const classes = e.target.className
      if (classes.includes("status_btn_click"))
      {
        this.setState({statusChangeUI:true})

      }
      else{
        this.setState({statusChangeUI:false})
      }

      }
      catch{}
      
    })
    // requests.getPushKey().then(
    //   (data) => { 
    //     this.setState({applicationServerPublicKey:data.public_key})
    //   }) push notifications
    // document.addEventListener("visibilitychange", () => {
    //   if (document.visibilityState === 'visible') {
    //     this.clearNotification()
    //    }
    //   }
    // )
    this.check_token_for_login()
  }

  check_token_for_login = ()=>{
    this.checkToken().then( (user) =>{    
      if(user._id)
      {
      this.startApp(user).then(
        ()=>{
          const wait_for_team= ()=>{
            if (this.state.team){
              this.setState({"loading":false})
            }
            else{
              setTimeout(
                wait_for_team
                ,200)
            }
          }
          setTimeout(wait_for_team,200) 

          this.run_registered_listeners()
      }
        )
      }
    })
    .catch((e)=>{
      console.log('no user')
    })
  }
  componentDidUpdate(p,previous_state)
  {
    if (this.state.team_or_contacts !== previous_state.team_or_contacts)
    { //this is questionable, may want to be able to navigate without clearing conversation
     this.setState({
        text_targets:[],}) 
      
      setTimeout(()=>
      this.setState({
        conversation:{
        _id:"",
        carrier:!this.state.team_or_contacts,
        messages:[],
        endpoints:[]
        }
      }) , 0)
    }
    
    if (previous_state.outbound_text !== this.state.outbound_text || !_.isEqual(previous_state.text_targets,this.state.text_targets) )
    {
      if(!_.isEqual(previous_state.text_targets,this.state.text_targets))
      {
        const unique_text_targets = [...new Set(this.state.text_targets)]
        this.setState({
          text_targets:unique_text_targets
        })
      }
      let source 
      if (this.state.team_or_contacts)
      {
       source = this.state._id
      }
      else 
      {
        source = this.state.outbound_text
      }

      this.update_conversation_from_endpoints_update(source,this.state.text_targets) 
    }
    if(previous_state.conversation._id !== this.state.conversation._id)
    {
      if (this.state.conversation._id.length > 0 && this.state.conversation._id!== this.state._id && this.state.conversation.messages.length>0)
      {
        const conversation_exists = this.state.conversations.find((o)=>o._id===this.state.conversation._id)
        if (conversation_exists){
          this.read_conversation(this.state.conversation._id)
        }
      }
    }

    // //Clean selected_call
    let selected_call=null
    for (const call of this.state.sessions)
    {

       if (this.state.selected_call===null || call._id === this.state.selected_call._id) 

      {
        selected_call = call
        break
      }
    }
    if (this.state.selected_call !== selected_call)
    {this.setState({selected_call:selected_call})}
    if (this.state.selected_call === null) {
      if (this.state.transfer_call_modal === true)
      this.setState({"transfer_call_modal":false})
    }
    // End of selected_call clean

  }
  read_conversation = (conversation_id) =>{
    requests.read_conversation({"conversation_id":conversation_id}).then(response=>{
      if (response.success){
        const new_messages = this.state.new_messages.filter( (o)=>
          !(o._id === conversation_id &&  new Date(response.timestamp) > new Date(o.timestamp_created))
        )
        this.setState({new_messages:new_messages})
      }
    })

  }
  registerSip = async (user) =>{
    //JsSIP.debug.enable('JsSIP:*'); 
    await requests.get_sip_address().then(response=> {
      const sip_address = response.sip_address
      const http_port = response.http_port
      this.setState({sip_address:sip_address})
      const socket = new JsSIP.WebSocketInterface(`wss://${sip_address}:${http_port}/ws`),
      configuration = {
          'sockets': [ socket ],
          'uri':`sip:${user._id}-web@${sip_address}`,
          'password': `${user.sip_settings.password}`,
          'register_expires':80,
      };
      const phone = new JsSIP.UA(configuration);
      phone.start()
      window.phone = phone
      window.debug_phone = JsSIP.debug
      const ringtone = new window.Audio(ring)
      ringtone.loop = true
      this.setState({ringtone :ringtone})
      phone.on("newRTCSession", (data)=>{
               if (data.session._direction === "incoming") {
                // incoming call here
                // send busy code if dnd is on
                if (this.state.dnd === true) {
                    data.session.terminate();
                }   
                this.state.ringtone.play();
              }
              let sessions = [...this.state.sessions]
              sessions.push(data.session)
              this.setState({sessions: sessions})
      })
      return 'success'
    }
    )
  }
  createSessions = () => {
      const divs = []
      for ( const index in this.state.sessions)
          { 
            divs.push(
            <Session 
              key={this.state.sessions[index]._id} 
              terminate={this.terminate} 
              ringtone = {this.state.ringtone} 
              session={this.state.sessions[index]}
              index = {index}
              />)
            }
      return divs
    }
  

  showHideComponent= (name)=> {
    this.setState({dialPad:false,
      home:false,
      directory:false,
      history:false,
      CallLogs:false,
      profile:false})
    this.setState ({ [name] :true})
  };
  hide = (ui) =>{
    this.setState ({ [ui] :false})
  }
  show = (ui) => {
    this.setState ({[ui]:true,})
  }
  update_status = (status) =>{
    this.setState(prevState => ({
      user: {                   // object that we want to update
          ...prevState.user,    // keep all other key-value pairs
          status: status       // update the value of specific key
      }
  }))
  this.setState({statusChangeUI:false})
  }

terminate = (_id) => {
  const arr = (this.state.sessions.filter(obj => obj._id !== _id))
  this.setState({sessions: arr});
}
update_conversation_from_endpoints_update =  (source_endpoint,destination_endpoints) => {
    //this.setState({messaging_app:true})
    const endpoints = [...destination_endpoints]
    endpoints.push(source_endpoint)
    const unique_endpoints = [...new Set(endpoints)];
    unique_endpoints.sort()
    const conversation_id = unique_endpoints.join(";")
    let conversation = {
      _id:conversation_id,
      endpoints:unique_endpoints,
      messages:[],
      carrier:!(this.state.team_or_contacts) // Is this gonna fail?
    }
    for (const obj of this.state.conversations){
      if (obj._id === conversation_id)
          {      
            conversation = obj
          }    
      }
    this.setState({
      conversation:conversation
    })
  }
  update_endpoints = (carrier, endpoints) =>{
    if (carrier)
    {
      let outbound_text = false
      for (const number of endpoints)
      {
        if (number === this.state.outbound_text) {
          outbound_text = number
          break
        }
      }
      if (!outbound_text)
        {
          for (const number of endpoints)
        {
          if (this.state.permissions.inbound_text.find(o=>o.number === number)) //sets outbound
            {
              outbound_text=number
              this.setState({
              outbound_text:outbound_text,
            })
            break
            }
        }
      }
      let x = new Set(endpoints)
      x.delete(outbound_text)
      this.setState({"text_targets":[...x]})
      
    }
    else {
      this.setState({"text_targets": endpoints})
    }
}
go_to_conversation = (carrier,endpoints) =>{
  this.setState({messaging_app:true})
  this.update_endpoints(carrier,endpoints)
}
updateToken = (token,_id,user) => {
  this.setState({token:token,_id:_id,user:user})
}
team_contact_toggle = () =>{
  this.setState({team_or_contacts: !this.state.team_or_contacts})
}
group_myself_toggle = () =>{
  this.setState({group_or_myself: !this.state.group_myself_toggle})
}
updateGlobalState =(key,update)=>{
  this.setState ({[key]:update})
}


render() {
    let page
    const showDialPad = this.state.dialPad;
    const showHome = this.state.home;
    const showDirectory = this.state.directory;
    const showNotification = this.state.notification;
    const showProfile = this.state.profile
    if ( this.state.token) {
      page = 
      <MainContext.Provider key="context" value={this.state}>
        <div style={{cursor:this.state.loading?"wait":"auto"}}  >
    {[ 
      <div id="island">
      <Status key='status' statusChangeUI={this.state.statusChangeUI} status={this.state.user.status} update_status={this.update_status} />
      {window.phone?.isRegistered() || <img className="notifier" alt="offline" style={{height:"20px"}} src={offline_img}></img>}
      {this.state.new_messages.length >0 && <NewMessageIcon  
        new_messages={this.state.new_messages}
        conversations={this.state.conversations}
          update_global={this.state.update_global}
        />}
      {<CallOperator key="call_operator" sessions={this.state.sessions}/>}
      </div>,
      showHome && <Home key='home' show={this.show} missedCalls={this.state.missedCalls} new_messages={this.state.new_messages} setToken={this.setToken}/>,
      showDialPad && <DialPad key='dialpad' call_options = {this.state.call_options} />,
      showDirectory && 
      <Directory key='directory'
                        team_or_contacts={this.state.team_or_contacts} 
                        team_contact_toggle={this.team_contact_toggle} 
                        team={this.state.team} 
                        update_global = {this.updateGlobalState}
                        go_to_conversation={this.go_to_conversation}
                        contacts = {this.state.contacts || this.state.user.contacts} //Refresh token needed, for now on update of new contact we include it. Can also save in cookies
                         />
      ,
      showProfile && <Profile key='profile' updateToken={this.updateToken}  />,
      showNotification && <Notification key='notification' />,
      this.createSessions(),
      this.state.CallLogs && <CallLogs key="call_logs" 
                              group_or_myself={this.state.group_or_myself} 
                              group_myself_toggle={this.group_myself_toggle} 
                              missed_call_checker={this.missed_call_checker} 
                              updateLogTimestamp={this.updateGlobalState} 
                              selfExt={this.state.user.extension} 
                              hide={this.hide}  />,
      
      this.state.messaging_app &&
      <MessagingApp
      key="MessagingApp" 
      update_endpoints={this.update_endpoints} 
      team_or_contacts={this.state.team_or_contacts} 
      team_contact_toggle={this.team_contact_toggle} 
      team={this.state.team}
      contacts = {this.state.contacts || this.state.user.contacts} //needs improvement
      conversations={this.state.conversations} 
      conversation={this.state.conversation} 
      update_global={this.updateGlobalState} 
      setToken={this.setToken} 
      permissions = {this.state.permissions}
      read_conversation={this.read_conversation}
      _id = {this.state._id} 
      token={this.state.token} 
      hide={this.hide}  /> 
      ,
      this.state.voicemail_app && <VoicemailApp hide={this.hide}  />,
      //<Push applicationServerPublicKey={this.state.applicationServerPublicKey} swRegistration = {this.state.sw}  />,
      <Footer state= {this.state} key="footer" handler = {this.showHideComponent} /> 
    ]}
      </div>
    </MainContext.Provider>
    } 
    else 
    {
      page = <Login /*notifyMe={this.notifyMe} */ startApp={this.startApp} getStatus={this.getStatus} updateToken={this.updateToken} check_token_for_login={this.check_token_for_login} />;
    }

    return(
    <div> 
      {page}
    </div>
  );
  }
}

